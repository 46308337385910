exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-1-graphic-project-1-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/graphic-project1/graphic-project1.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-1-graphic-project-1-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-2-graphic-project-2-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/graphic-project2/graphic-project2.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-2-graphic-project-2-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-3-graphic-project-3-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/graphic-project3/graphic-project3.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-3-graphic-project-3-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-4-graphic-project-4-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/graphic-project4/graphic-project4.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-4-graphic-project-4-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-5-graphic-project-5-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/graphic-project5/graphic-project5.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-5-graphic-project-5-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-6-graphic-project-6-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/graphic-project6/graphic-project6.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-6-graphic-project-6-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-7-graphic-project-7-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/graphic-project7/graphic-project7.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-7-graphic-project-7-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-8-graphic-project-8-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/graphic-project8/graphic-project8.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-graphic-project-8-graphic-project-8-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-1-project-1-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/portfolio1/project1.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-1-project-1-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-2-project-2-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/portfolio2/project2.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-2-project-2-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-3-project-3-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/portfolio3/project3.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-3-project-3-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-4-project-4-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/portfolio4/project4.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-4-project-4-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-5-project-5-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/portfolio5/project5.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-5-project-5-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-6-project-6-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/dev/bhavisha-new/site/portfolio/portfolio6/project6.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-portfolio-portfolio-6-project-6-mdx" */)
}

